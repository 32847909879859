var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "b-container",
        [
          _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { cols: "12" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "mb-2",
                      attrs: { title: "Logger Management" },
                    },
                    [
                      _c(
                        "b-container",
                        [
                          _c(
                            "b-row",
                            [
                              _c("oem-api-table", {
                                ref: "table",
                                attrs: {
                                  action: "admin/getLoggers",
                                  fields: _vm.fields,
                                  addModal: "admin-add-logger",
                                },
                                on: { input: _vm.editLogger },
                                model: {
                                  value: _vm.selected_logger,
                                  callback: function ($$v) {
                                    _vm.selected_logger = $$v
                                  },
                                  expression: "selected_logger",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          attrs: { id: "admin-edit-logger", title: "Edit Logger", size: "lg" },
        },
        [
          _c(
            "b-container",
            [
              _c(
                "b-row",
                { staticClass: "mb-4" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "12" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { title: "Logger Management" } },
                        [
                          _c(
                            "b-container",
                            [
                              _c(
                                "b-row",
                                { staticClass: "mt-2" },
                                [
                                  _c(
                                    "b-col",
                                    [
                                      _c(
                                        "b-form",
                                        [
                                          _c(
                                            "b-form-group",
                                            { attrs: { label: "Name" } },
                                            [
                                              _c("b-input", {
                                                attrs: {
                                                  disabled: "",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.logger.name,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.logger,
                                                      "name",
                                                      $$v
                                                    )
                                                  },
                                                  expression: "logger.name",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-form-group",
                                            { attrs: { label: "Description" } },
                                            [
                                              _c("b-input", {
                                                attrs: {
                                                  disabled: "",
                                                  type: "text",
                                                },
                                                model: {
                                                  value: _vm.logger.description,
                                                  callback: function ($$v) {
                                                    _vm.$set(
                                                      _vm.logger,
                                                      "description",
                                                      $$v
                                                    )
                                                  },
                                                  expression:
                                                    "logger.description",
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-3",
                                  attrs: { "align-h": "center" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "auto" } },
                                    [
                                      this.selected_logger
                                        ? _c(
                                            "b-btn",
                                            {
                                              attrs: {
                                                variant: "success",
                                                disabled: !_vm.selected_logger,
                                                to:
                                                  "/logger/" +
                                                  this.selected_logger.id,
                                              },
                                            },
                                            [
                                              _vm._v(" Go to Logger Page "),
                                              _c("fa-icon", {
                                                attrs: { icon: "arrow-right" },
                                              }),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "b-row",
                                {
                                  staticClass: "mt-4",
                                  attrs: { "align-h": "center" },
                                },
                                [
                                  _c(
                                    "b-col",
                                    { attrs: { cols: "12" } },
                                    [
                                      _c(
                                        "b-card",
                                        {
                                          attrs: {
                                            header: "Danger Zone",
                                            "border-variant": "danger",
                                            "header-border-variant": "danger",
                                            "header-text-variant": "danger",
                                            align: "center",
                                          },
                                        },
                                        [
                                          _c(
                                            "b-container",
                                            [
                                              _c(
                                                "b-row",
                                                { staticClass: "mt-1" },
                                                [
                                                  _c(
                                                    "b-col",
                                                    { attrs: { cols: "12" } },
                                                    [
                                                      _c(
                                                        "p",
                                                        { staticClass: "lead" },
                                                        [
                                                          _vm._v(
                                                            "Regenerate Access Key"
                                                          ),
                                                        ]
                                                      ),
                                                      _c(
                                                        "b-input-group",
                                                        {
                                                          attrs: {
                                                            prepend:
                                                              "Access Key:",
                                                          },
                                                        },
                                                        [
                                                          _c("b-form-input", {
                                                            attrs: {
                                                              disabled: "",
                                                            },
                                                            model: {
                                                              value:
                                                                this.logger
                                                                  .access_key,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    this.logger,
                                                                    "access_key",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "this.logger.access_key",
                                                            },
                                                          }),
                                                          _c(
                                                            "b-input-group-append",
                                                            [
                                                              _c(
                                                                "b-btn",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "secondary",
                                                                    disabled:
                                                                      !_vm.selected_logger,
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return this.$copyText(
                                                                          this
                                                                            .logger
                                                                            .acces_code
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "fa-icon",
                                                                    {
                                                                      attrs: {
                                                                        icon: "copy",
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-btn",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "danger",
                                                                    disabled:
                                                                      !_vm.selected_logger,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Regenerate "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "p",
                                                        {
                                                          staticClass:
                                                            "text-danger",
                                                        },
                                                        [
                                                          _vm._v(
                                                            " Regenerating an access key will remove the current owners and accessors from the logger. "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                "b-row",
                                                {
                                                  staticClass: "mt-3",
                                                  attrs: { "align-v": "start" },
                                                },
                                                [
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            [
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c("b-col", [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "lead",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          "Regenerate and Download Logger Token"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c(
                                                                    "b-col",
                                                                    {
                                                                      attrs: {
                                                                        cols: "12",
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        "b-btn",
                                                                        {
                                                                          attrs:
                                                                            {
                                                                              variant:
                                                                                "danger",
                                                                              disabled:
                                                                                !_vm.selected_logger,
                                                                            },
                                                                        },
                                                                        [
                                                                          _vm._v(
                                                                            " Revoke and Regenerate Logger Token "
                                                                          ),
                                                                        ]
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                              _c(
                                                                "b-row",
                                                                [
                                                                  _c("b-col", [
                                                                    _c(
                                                                      "p",
                                                                      {
                                                                        staticClass:
                                                                          "text-danger",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          " This will prevent the logger from accessing PMGateway. Use only as a reprovisioning tool. "
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]),
                                                                ],
                                                                1
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _c(
                                                    "b-col",
                                                    {
                                                      attrs: {
                                                        cols: "12",
                                                        md: "6",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "b-row",
                                                        { staticClass: "mt-3" },
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "p",
                                                                {
                                                                  staticClass:
                                                                    "lead",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "Delete Logger from System"
                                                                  ),
                                                                ]
                                                              ),
                                                            ]
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                      _c(
                                                        "b-row",
                                                        [
                                                          _c(
                                                            "b-col",
                                                            {
                                                              attrs: {
                                                                cols: "12",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "b-btn",
                                                                {
                                                                  attrs: {
                                                                    variant:
                                                                      "danger",
                                                                    disabled:
                                                                      !_vm.selected_logger,
                                                                  },
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    " Delete Logger and All Files from System "
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "b-modal",
        {
          ref: "provisionModal",
          attrs: { id: "admin-add-logger", title: "Provision a New Logger" },
          on: { ok: _vm.provisionLogger },
        },
        [
          _vm._v(
            " Start the provisioning process by entering the serial number of the logger below. "
          ),
          _c(
            "b-form",
            [
              _c(
                "b-form-group",
                { attrs: { label: "Serial Number" } },
                [
                  _c("b-input", {
                    attrs: {
                      type: "text",
                      state: _vm.provision_logger.error === null ? null : false,
                    },
                    model: {
                      value: _vm.provision_logger.serial,
                      callback: function ($$v) {
                        _vm.$set(_vm.provision_logger, "serial", $$v)
                      },
                      expression: "provision_logger.serial",
                    },
                  }),
                  _c("b-form-invalid-feedback", [
                    _vm._v(_vm._s(_vm.provision_logger.error)),
                  ]),
                  _c("b-form-text", [
                    _vm._v("Serial Number in the form PMXSXXXX"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }